<div class="p-3">
  <h2 mat-dialog-title>{{ areaId ? 'Editar' : 'Crear' }} Area</h2>
  <div mat-dialog-content>
    <form [formGroup]="areaForm" (ngSubmit)="onSubmit()">
      <div class="row pt-2">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name" />
          <mat-error>{{ getErrorMessage(controls.name) }}</mat-error>
        </mat-form-field>
      </div>

      <div class="row pt-2">
        <ngx-select
          [formGroup]="areaForm"
          control="customer_id"
          [$obs]="$customers"
          [defaultItems]="[$customer]"
          appendTo="body"
          bindLabel="first_name"
          bindValue="id"
          label="Cliente"></ngx-select>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div>
            <confirm-button [loading]="loading"></confirm-button>
          </div>
          <div>
            <button
              (click)="dialogRef.close()"
              type="button"
              mat-raised-button
              color="warn">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
