<filters-container-personalized>
  <ng-container *ngFor="let filter of filters">
    <filter-field-personalized
      [filter]="filter"
      (deleteFilter)="removeFilter($event)"></filter-field-personalized>
  </ng-container>

  <div class="row">
    <form [formGroup]="form" (ngSubmit)="addFilter()">
      <div class="row">
        <mat-form-field appearance="outline" class="col-md-4 p-1 mb-0">
          <mat-label>OR</mat-label>
          <mat-select formControlName="operator">
            <mat-option value="OR"> O </mat-option>
            <mat-option value="AND"> Y </mat-option>
          </mat-select>
          <mat-error *ngIf="controls.operator.status == 'INVALID'">
            Inválido
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-8 p-1 mb-0">
          <mat-label>Operador</mat-label>
          <mat-select formControlName="logicOperator">
            <mat-option value="="> Igual a </mat-option>
            <mat-option value="LIKE"> Contiene a</mat-option>
            <mat-option value=">"> Mayor que </mat-option>
            <mat-option value="<"> Menor que </mat-option>
            <mat-option value=">="> Mayor o igual a </mat-option>
            <mat-option value="<="> Menor o igual a </mat-option>
            <mat-option value="!="> Diferente a </mat-option>
          </mat-select>
          <mat-error *ngIf="controls.logicOperator.status == 'INVALID'">
            Operador inválido
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-12 p-1 mb-0">
          <mat-label>Buscar</mat-label>
          <mat-select formControlName="option">
            <mat-option *ngFor="let header of headers" [value]="header">
              {{ header.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              controls.option.status == 'INVALID' &&
              (controls.option.touched || controls.option.dirty)
            ">
            Buscar inválido
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row pb-2">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.invalid">
          Agregar <mat-icon>add</mat-icon>
        </button>
      </div>
    </form>
  </div>

  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="applyFilters()">
          Buscar <mat-icon>search</mat-icon>
        </button>
      </div>

      <div>
        <button
          (click)="cleanFilters()"
          mat-raised-button
          color="secondary"
          type="button">
          Limpiar <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</filters-container-personalized>
