import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableHeader } from '@shared/interfaces/table-header.interface';
import { SearchParams } from '@utils/classes/list-params';
import { SortTable } from '@utils/classes/sort-table';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styles: [
    `
      th.sort-asc {
        /* background-image: url("/../../../assets/vendor/datatables/images/sort_asc.png"); */
        background-image: url('/../../../assets/images/sort/sort_asc.png');
        background-repeat: no-repeat;
        background-position: center right;
      }

      th.sort-desc {
        background-image: url('/../../../assets/images/sort/sort_desc.png');
        background-repeat: no-repeat;
        background-position: center right;
      }

      th.no-sort {
        background-image: url('/../../../assets/images/sort/sort_both.png');
        background-repeat: no-repeat;
        background-position: center right;
      }

      .sortable {
        cursor: pointer;
      }

      table.loading {
        background-image: url('https://tensa.slide.com.mx/assets/img/loader-button.gif');
        background-size: 50px 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.1);
        min-height: 100px;
      }
    `,
  ],
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() params = new BehaviorSubject<SearchParams>(new SearchParams());
  @Input() actions: boolean = true;
  @Input() headers: TableHeader[] = [];
  @Input() loading: boolean = false;
  @Input() maxColumns = 5;
  search: FormControl = new FormControl();
  $unsubscribe = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this.$unsubscribe),
        switchMap(term => {
          this.onSearch(term);
          return of(term);
        })
      )
      .subscribe();
  }

  sort(column: string, sortable: boolean) {
    const params = this.params.getValue();
    if (!sortable) {
      return;
    }
    const { filterOrder, filterColumn } = new SortTable(
      column,
      params.sortBy,
      params.sortOrder
    ).sort();
    params.sortOrder = filterOrder;
    params.sortBy = filterColumn;
    this.params.next(params);
  }

  onSearch(term: string) {
    const params = this.params.getValue();
    params.page = 1;
    params.search = term;
    this.params.next(params);
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  sortBy(column: string) {}

  getSortClass(column: string, sortable: boolean) {
    if (!sortable) {
      return '';
    }
    return this.params.getValue().sortBy == column
      ? this.params.getValue().sortOrder == 'ASC'
        ? 'sort-asc'
        : this.params.getValue().sortOrder == 'DESC'
        ? 'sort-desc'
        : 'no-sort'
      : 'no-sort';
  }
}
