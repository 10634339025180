<ng-container [ngSwitch]="header.filter.type">
  <div class="row" *ngSwitchCase="'text'">
    <mat-form-field appearance="outline" class="col-md-3 mx-0 px-0 bx-0">
      <mat-select [(ngModel)]="header.filter.operator">
        <mat-option
          *ngFor="let operator of operators"
          [value]="operator.operator">
          {{ operator.symbol }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-9 mx-0 px-0 bx-0">
      <mat-label>{{ header.label }}</mat-label>
      <input matInput type="text" [(ngModel)]="header.filter.value" />
    </mat-form-field>
  </div>

  <div class="row" *ngSwitchCase="'number'">
    <mat-form-field appearance="outline" class="col-md-3 mx-0 px-0 bx-0">
      <mat-select [(ngModel)]="header.filter.operator">
        <mat-option
          *ngFor="let operator of operators"
          [value]="operator.operator">
          {{ operator.symbol }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-9 mx-0 px-0 bx-0">
      <mat-label>{{ header.label }}</mat-label>
      <input matInput type="number" [(ngModel)]="header.filter.value" />
    </mat-form-field>
  </div>

  <div class="row" *ngSwitchCase="'date'">
    <mat-form-field appearance="outline" class="col-md-3 mx-0 px-0 bx-0">
      <mat-select [(ngModel)]="header.filter.operator">
        <mat-option
          *ngFor="let operator of operators"
          [value]="operator.operator">
          {{ operator.symbol }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-9 mx-0 px-0 bx-0">
      <mat-label>{{ header.label }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="header.filter.value" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row" *ngSwitchCase="'customers'">
    <mat-form-field appearance="outline" class="mx-0 px-0 bx-0">
      <mat-label>Cliente</mat-label>
      <mat-select [(ngModel)]="header.filter.value">
        <mat-option *ngFor="let customer of customers" [value]="customer.id">
          {{ customer.first_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row" *ngSwitchDefault>
    <mat-form-field appearance="outline" class="col-md-3 mx-0 px-0 bx-0">
      <mat-select [(ngModel)]="header.filter.operator">
        <mat-option
          *ngFor="let operator of operators"
          [value]="operator.operator">
          {{ operator.symbol }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-9 mx-0 px-0 bx-0">
      <mat-label>{{ header.label }}</mat-label>
      <input matInput type="text" [(ngModel)]="header.filter.value" />
    </mat-form-field>
  </div>
</ng-container>
