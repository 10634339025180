import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanMatch {
  constructor(private router: Router, private jwtHelper: JwtHelperService) {}
  canMatch(route: Route, segments: UrlSegment[]) {
    const isUsersigned = this.isUserSignedIn();
    return !this.isUserSignedIn();
  }

  private isUserSignedIn() {
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.router.navigate(['/pages/home']);
      return true;
    } else {
      return false;
    }
  }
}
