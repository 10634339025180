import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationResponse } from '@core/interfaces/pagination-response.interface';
import { Repository } from '@core/repository/repository';
import { IDocumentType } from '@pages/templates/document-types/interfaces/document-type.interface';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentTypesService extends Repository<IDocumentType> {
  constructor() {
    super();
    this.path = 'document-types';
  }

  findByUser(areaId: number, params?: HttpParams) {
    const url = `by-user/${areaId}`;
    return this.get<IPaginationResponse<IDocumentType>>({ url, params });
  }

  findByArea(areaId: number, params?: HttpParams) {
    const url = `by-area/${areaId}`;
    return this.get<IPaginationResponse<IDocumentType>>({ url, params });
  }

  getAssignableAccounts(userId: number, params?: HttpParams) {
    const url = `assignable-by-user/${userId}`;
    return this.get<IPaginationResponse<IDocumentType>>({
      url,
      params,
    }).pipe(
      map(response => {
        const data = response.data.map(dt => {
          return {
            id: dt.id,
            name: dt.name,
            customer: dt.area.customer.first_name,
            area: dt.area.name,
            active: dt.user != null,
          };
        });
        return { ...response, data };
      })
    );
  }
}
