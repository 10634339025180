import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BasePage } from '@common/base-page.component';
import { ConfigService } from '@config/config.service';
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import { OcrSocketService } from '@core/service/ocr-socket.service';
import { environment } from '@environments/environment';
import { UsersService } from '@pages/users/users/service/users.service';
import { SettingsService } from '@settings/settings/service/settings.service';
import { takeUntil, tap } from 'rxjs';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BasePage implements OnInit, AfterViewInit {
  public config: any = {};
  notifications: any[] = [];
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  logoImg: string;
  logoWidth: number = 100;
  userImg: string =
    environment.API_URL +
    environment.API_VERSION +
    '/files/users/img/' +
    'user.jpeg';
  userFullName: string;
  cleanURl: string;
  photoURL: string;
  dataSharedUser = null;
  imgSrc: string;

  @ViewChild('notificationsButton', { static: true, read: ElementRef })
  notificationButton: ElementRef;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private userService: UsersService,
    private settingsService: SettingsService,
    private ocrSockerService: OcrSocketService
  ) {
    super();
  }

  ngOnInit() {
    this.viewUploads().subscribe();
    this.cleanURl =
      environment.API_URL + environment.API_VERSION + '/files/users/img/';

    this.getUser();

    this.logoImg = 'assets/images/logo.png';

    this.dataSharedUser = this.configService.dataUser.subscribe(res => {
      this.userFullName = res.name;

      this.userImg = res.img
        ? this.cleanURl + res.img
        : this.cleanURl + 'user.jpeg';
    });

    this.photoURL =
      environment.API_URL + environment.API_VERSION + '/files/logo/img/';
    this.getSettings();
  }

  viewUploads() {
    return this.ocrSockerService.$subscriber
      .pipe(takeUntil(this.$unsubscribe))
      .pipe(
        tap(upload => {
          this.notificationButton.nativeElement.click();

          const newNot = upload.files.map(file => {
            return {
              name: file,
              message: 'Esperando...',
              icon: 'description',
              status: 'process',
              progress: 0,
              mode: 'buffer',
            };
          });

          this.notifications = [...this.notifications, ...newNot];

          this.listenUploadStatus(upload.id).subscribe();
        })
      );
  }

  listenUploadStatus(id: string) {
    return this.ocrSockerService.uploadOcr(id).pipe(
      tap(uploadStatus => {
        this.newNotification(uploadStatus);
      })
      // takeWhile(
      //   (status: any) =>
      //     this.notifications.filter(n => n.status == 'process').length > 0
      // ),
      // finalize(() => {
      //   this.notifications.forEach(notification => {
      //     console.log(notification);
      //   });
      // })
    );
  }

  insideClick(event: Event) {
    console.log(event);
  }

  removeAllNotifications() {
    this.notifications = [];
  }

  removeNotification(index: number) {
    this.notifications.splice(index, 1);
  }

  newNotification(status: any) {
    console.log('line 146', this.notifications);
    console.log('line 147', status);

    const index = this.notifications.findIndex(
      n => n.name == status.filename && n.progress != 100
    );

    if (status.status == 'error') {
      status.progress = 0;
    }

    if (index < 0) {
      this.notifications.push({
        name: status.filename,
        message: status.message,
        icon: 'description',
        status: status.status,
        mode: 'determinate',
        progress: status.progress,
      });
      return;
    }

    this.notifications[index].progress = status.progress;
    this.notifications[index].message = status.message;
    this.notifications[index].status = status.status;
    this.notifications[index].mode = status.mode;
  }

  ngAfterViewInit(): void {}

  getUser() {
    let userToken = this.authService.getUser();

    this.userService.getById(userToken.id).subscribe(res => {
      this.userFullName = res.name;

      this.userImg =
        res.img == null ? this.cleanURl + 'user.jpeg' : this.cleanURl + res.img;
    });
  }

  getSettings() {
    this.settingsService.getAllPaginated().subscribe((res: any) => {
      if (res.logo != '') {
        this.logoImg = this.photoURL + res.logo;
      }

      this.logoWidth = res.logo_width;
    });
  }

  ngOnDestroy() {
    this.dataSharedUser.unsubscribe();
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  goToProfile() {
    this.router.navigate(['/pages/perfil']);
  }

  goToSettings() {
    this.router.navigate(['/pages/settings/settings']);
  }

  logout() {
    const user = this.getUser();
    this.authService.logout(user);
  }
}
