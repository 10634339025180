import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IArea } from '@areas/interfaces/area.interface';
import { BasePage } from '@common/base-page.component';
import { AreasService } from '@customer/areas/service/areas.service';
import { AreaForm } from '@customer/areas/utils/area-form';
import { BranchService } from '@customer/branches/service/branches.service';
import { CustomersService } from '@customer/customers/service/customers.service';
import { ICustomer } from '@customers/interfaces/customer.interface';
import { SearchParams } from '@utils/classes/list-params';
import { RECORD_SAVED } from '@utils/constants/global-messages';
interface DialogData {
  id: number;
  customerId: number;
}

@Component({
  selector: 'app-areas-form',
  templateUrl: './areas-form.component.html',
  styles: [],
})
export class AreasFormComponent extends BasePage implements OnInit {
  areaForm = this.fb.group(new AreaForm());
  customers = [];
  branches = [];
  readonly areaId: number = null;
  customerId: number;
  $customers = this.customersService.getByUser.bind(this.customersService);
  $customer: ICustomer = null;
  get controls() {
    return this.areaForm.controls;
  }
  constructor(
    private fb: FormBuilder,
    private areasService: AreasService,
    private customersService: CustomersService,
    private branchesService: BranchService,
    public dialogRef: MatDialogRef<AreasFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
    this.areaId = this.data.id;
    this.customerId = this.data.customerId;
  }

  ngOnInit(): void {
    if (this.areaId) {
      this.getArea();
    }

    if (this.customerId != undefined) {
      this.controls.customer_id.setValue(this.customerId);
    }

    this.getAllClients();
    this.getAllBranchesById(this.customerId);
  }

  getArea() {
    this.areasService.getById(this.areaId).subscribe({
      next: item => {
        const params = new SearchParams();
        params.addFilter('id', item.customer.id);
        this.$customer = item.customer;
        this.areaForm.patchValue(item);
        this.areaForm.patchValue({
          customer_id: item.customer.id,
        });
      },
    });
  }

  getAllClients() {
    this.customersService.getAllPaginated().subscribe({
      next: (items: any) => {
        this.customers = items;
      },
    });
  }

  getAllBranchesById(id: number) {
    this.branchesService.getAllPaginated().subscribe({
      next: items => {
        this.branches = items.data;
      },
    });
  }

  onSubmit() {
    if (!this.areaForm.valid) {
      return;
    }
    const $obs = this.areaId
      ? this.update().subscribe({
          next: area => this.handleSuccess(),
          error: error => this.handleError(error),
        })
      : this.save().subscribe({
          next: area => this.handleSuccess(area),
          error: error => this.handleError(error),
        });
  }

  save() {
    return this.areasService.create(this.areaForm.value);
  }

  update() {
    return this.areasService.update(this.areaId, this.areaForm.value);
  }

  handleError(error: HttpErrorResponse) {
    this.loading = false;
    this.formServerErrors(error, this.areaForm);
  }

  handleSuccess(area?: IArea) {
    this.showToast('success', RECORD_SAVED);
    this.loading = false;
    this.dialogRef.close(area ?? true);
  }
}
