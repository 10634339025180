import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@config/config.service';
import { IUserConfig } from '@core/interfaces/user-config.interface';
import { Repository } from '@core/repository/repository';
import { AllowedFilesService } from '@core/service/allowed-files.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, firstValueFrom, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Repository {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private configService: ConfigService,
    private allowedFilesService: AllowedFilesService
  ) {
    super();
    this.path = 'auth';
  }

  signIn(body: Object) {
    return this.post<{ token: string }>({ url: 'signin', body }).pipe(
      tap(async response => {
        this.saveToken(response.token);
        await this.configUser();

        // setTimeout(() => {
        this.router.navigate(['/pages/containers/search']);
        // }, 1000);
      })
    );
  }

  getUserConfig() {
    return this.get<IUserConfig>({ url: 'user-config' });
  }

  async configUser() {
    const config = await firstValueFrom(
      this.getUserConfig().pipe(catchError(() => of(null)))
    );
    if (!config) {
      return false;
    }
    const permissions = config.permissions.map(
      permission => permission.short_name
    );
    this.permissionsService.flushPermissions();
    this.permissionsService.loadPermissions(permissions);
    this.allowedFilesService.loadExtensions(config.allowedFiles);
    this.configService.$menu.next(config.menu);
    return true;
  }

  private saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  private getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    const token = this.getToken();
    const payload = token.split('.')[1];
    const payloadDecoded = atob(payload);
    return JSON.parse(payloadDecoded);
  }

  resetPassword(body: Object) {
    return this.post({ url: 'password-reset', body });
  }

  passwordReset(data) {
    return this.httpClient.post(
      `${this.route}${this.path}/reset-password`,
      data
    );
  }

  async logout(user) {
    await this.post({ url: 'signout', body: { user } }).toPromise();

    localStorage.removeItem('token');
    this.router.navigate(['/auth/signin']);
  }
}
