<ng-container>
  <div class="d-flex justify-content-between">
    <div class="pt-3 d-flex justify-content-center">
      {{ filter.operator }}
    </div>

    <div class="p-2 pt-3">{{ filter.logicOperator }}</div>

    <mat-form-field
      appearance="outline"
      class="mx-0 px-0 bx-0"
      style="width: 55%">
      <mat-label>{{ filter.option.label }}</mat-label>
      <input matInput type="text" [(ngModel)]="filter.value" />
    </mat-form-field>

    <button mat-icon-button (click)="removeFilter(filter)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-container>
