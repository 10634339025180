import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IArea } from '@areas/interfaces/area.interface';
import { BasePage } from '@common/base-page.component';
import { AreasService } from '@customer/areas/service/areas.service';
import { DocumentTypesService } from '@pages/templates/document-types/service/document-types.service';
import { DocumentTypeForm } from '@pages/templates/document-types/utils/document-type-form';
import { IDocumentType } from '@template/document-types/interfaces/document-type.interface';
import { RECORD_SAVED } from '@utils/constants/global-messages';

interface DialogData {
  id: number;
  areaId: number;
  clienteId: number;
}
@Component({
  selector: 'app-document-types-form',
  templateUrl: './document-types-form.component.html',
  styles: [],
})
export class DocumentTypesFormComponent extends BasePage implements OnInit {
  documentTypeForm = this.fb.group(new DocumentTypeForm());
  readonly documentTypeId: number = null;
  areas = [];
  areaId: number;
  clienteId: number;
  $areas = this.areasService.getAllPaginated.bind(this.areasService);
  $area: IArea = null;

  get controls() {
    return this.documentTypeForm.controls;
  }
  constructor(
    private fb: FormBuilder,
    private documentTypesService: DocumentTypesService,
    private areasService: AreasService,
    public dialogRef: MatDialogRef<DocumentTypesFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
    this.documentTypeId = this.data.id;
    this.areaId = this.data.areaId;
    this.clienteId = this.data.clienteId;
  }

  ngOnInit(): void {
    if (this.documentTypeId) {
      this.getDocumentType();
    }
    if (this.areaId) {
      this.controls.area_id.setValue(this.areaId);
    }

    if (this.clienteId) {
      this.getAreaForId();
    } else {
      this.getAreas();
    }
  }

  getDocumentType() {
    this.documentTypesService.getById(this.documentTypeId).subscribe({
      next: item => {
        console.log(item);
        this.$area = item.area;
        this.documentTypeForm.patchValue({
          area_id: item.area.id,
        });

        this.documentTypeForm.patchValue(item);
      },
    });
  }

  getAreas() {
    this.areasService.getAllPaginated().subscribe({
      next: items => {
        console.log(items);

        this.areas = items.data;
      },
    });
  }

  getAreaForId() {
    this.areasService.findByCustomer(this.clienteId).subscribe({
      next: (items: any) => {
        this.areas = items;
      },
    });
  }

  onSubmit() {
    if (!this.documentTypeForm.valid) {
      return;
    }
    const $obs = this.documentTypeId
      ? this.update().subscribe({
          next: () => this.handleSuccess(),
          error: error => this.handleError(error),
        })
      : this.save().subscribe({
          next: documentType => this.handleSuccess(documentType),
          error: error => this.handleError(error),
        });
  }

  save() {
    return this.documentTypesService.create(this.documentTypeForm.value);
  }

  update() {
    return this.documentTypesService.update(
      this.documentTypeId,
      this.documentTypeForm.value
    );
  }

  handleError(error: HttpErrorResponse) {
    this.loading = false;
    this.formServerErrors(error, this.documentTypeForm);
  }

  handleSuccess(documentType?: IDocumentType) {
    this.showToast('success', RECORD_SAVED);
    this.loading = false;
    this.dialogRef.close(documentType ?? true);
  }
}
