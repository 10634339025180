<ng-container [formGroup]="accountLevelForm">
  <div class="col-md-12">
    <div class="row delMrg">
      <div class="col-md-4 d-flex">
        <ngx-select
          class="col level"
          [formGroup]="accountLevelForm"
          control="customer"
          [$obs]="$customers"
          bindLabel="first_name"
          [defaultItems]="[$newCustomer]"
          bindValue="id"
          label="Cuenta Nivel 1 Cliente"></ngx-select>

        <div class="col" *ngIf="addLevels">
          <button
            matTooltip="Agregar cuentas"
            mat-mini-fab
            color="primary"
            class="header-dropdown mt-2"
            type="button"
            (click)="openCustomerForm()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <div class="col-md-4 d-flex">
        <ngx-select
          class="col level"
          [formGroup]="accountLevelForm"
          control="area"
          [args]="[this.customerId]"
          [$obs]="$areas"
          [defaultItems]="[$area]"
          bindLabel="name"
          bindValue="id"
          label="Cuenta Nivel 2 Area"></ngx-select>
        <div class="col" *ngIf="addLevels">
          <button
            matTooltip="Agregar cuentas"
            mat-mini-fab
            color="primary"
            class="header-dropdown mt-2"
            type="button"
            (click)="openAreaForm()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <div class="col-md-4 d-flex">
        <ngx-select
          class="col level"
          [formGroup]="accountLevelForm"
          control="documentType"
          [args]="[this.areaId]"
          [$obs]="$documentTypes"
          [defaultItems]="[$documentType]"
          bindLabel="name"
          bindValue="id"
          label="Cuenta Nivel 3 Tipo de Documento"></ngx-select>
        <div class="col">
          <button
            *ngIf="addLevels"
            matTooltip="Agregar cuentas"
            mat-mini-fab
            color="primary"
            class="header-dropdown mt-2"
            type="button"
            (click)="openDocumentTypeForm()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
