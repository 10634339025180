import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-button',
  template: `
    <button
      class="w-100"
      (click)="onConfirm()"
      mat-raised-button
      [type]="type"
      color="primary"
      [class.auth-spinner]="loading"
      [disabled]="loading"
      type="submit">
      {{ loading ? loadingText : text }}
    </button>
  `,
  styles: [],
})
export class ConfirmButtonComponent {
  @Input() loading: boolean = false;
  @Input() text = 'Guardar';
  @Input() loadingText = 'Guardando';
  @Input() type: 'button' | 'submit' = 'submit';
  @Output() confirm = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }
}
