<div class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <a
    href="javascript:void(0)"
    class="settingPanelToggle"
    (click)="toggleRightSidebar()">
    <app-feather-icons
      [icon]="'filter'"
      [class]="'setting-sidebar-icon'"></app-feather-icons>
  </a>
  <ng-scrollbar [style.height]="'90%'" visibility="hover">
    <div class="settingSidebar-body ps-container ps-theme-default">
      <div class="fade show active">
        <div class="setting-panel-header text-secondary">Filtro</div>
        <div class="p-15 border-bottom">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
