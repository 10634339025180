const CONFIRM_DELETE_TITLE = '¿Estás seguro?';
const CONFIRM_DELETE_TEXT = '¿Estás seguro que deseas eliminar este registro?';
// ? tiulos por defecto de los toast
const RECORD_DELETED = 'Registro eliminado correctamente';
const RECORD_SAVED = 'Registro guardado correctamente';
const ERROR_ON_DELETE = 'Ocurrió un error al eliminar el registro';
const ERROR_ON_SAVE = 'Ocurrió un error al guardar el registro';
const ERROR_ON_UPDATE = 'Ocurrió un error al actualizar el registro';
const ALERT_TITLES = {
  success: 'Exito',
  info: 'Info',
  warning: 'Advertencia',
  error: 'Error',
};
const getAlertTitles = (type: 'success' | 'info' | 'warning' | 'error') =>
  ALERT_TITLES[type];
export {
  CONFIRM_DELETE_TEXT,
  CONFIRM_DELETE_TITLE,
  RECORD_SAVED,
  RECORD_DELETED,
  ERROR_ON_DELETE,
  ERROR_ON_SAVE,
  ERROR_ON_UPDATE,
  getAlertTitles,
};
