import { Component, Input, OnInit } from '@angular/core';
import { ICustomer } from '@customers/interfaces/customer.interface';
import { CustomersService } from '@customers/service/customers.service';
import {
  DATE_FILTER_OPERATORS,
  NUMBER_FILTER_OPERATORS,
  TEXT_FILTER_OPERATORS,
} from '@shared/filter/utils/filter-operators';
import { TableHeader } from '@shared/interfaces/table-header.interface';

@Component({
  selector: 'filter-field',
  templateUrl: './filter-field.component.html',
  styles: [],
})
export class FilterFieldComponent implements OnInit {
  @Input() header: TableHeader;
  customers: ICustomer[] = [];
  operators: {
    operator: string;
    symbol: string;
  }[] = TEXT_FILTER_OPERATORS;

  constructor(private customersService: CustomersService) {}

  ngOnInit(): void {
    this.setOperators();
    if (this.header.filter.type == 'customers') {
      this.getAllClients();
    }
  }

  setOperators() {
    if (this.header.filter.type == 'text') {
      this.operators = TEXT_FILTER_OPERATORS;
    }
    if (this.header.filter.type == 'number') {
      this.operators = NUMBER_FILTER_OPERATORS;
    }

    if (this.header.filter.type == 'date') {
      this.operators = DATE_FILTER_OPERATORS;
    }
  }

  getAllClients() {
    this.customersService.getAllPaginated().subscribe({
      next: items => {
        this.customers = items.data;
      },
    });
  }
}
