<div class="input_wrap" [formGroup]="formGroup">
  <ng-select
    [items]="items"
    class="custom {{ isInvalid() ? 'invalid' : '' }} {{
      formGroup.get(control).value ? 'focus' : ''
    }}"
    [formControlName]="control"
    [searchable]="true"
    [loading]="loading"
    [appendTo]="appendTo"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [typeahead]="$input"
    (scrollToEnd)="nextPage()"
    (clear)="onClear()"
    (change)="setValueToOutput($event)"
    (close)="hasBeenClosed = true"
    (open)="hasBeenClosed = false"
    (focus)="focus = true"
    (blur)="blur = true">
    <ng-template ng-header-tmp>
      <small class="form-text text-muted"
        >Mostrando {{ items.length }} items de
        {{ pagination.totalItems }}</small
      >
    </ng-template>
  </ng-select>
  <label for="">{{ label }} {{ isRequired() ? '*' : '' }}</label>
  <div class="error-container">
    <mat-error *ngIf="isInvalid()">{{ getErrorMessage() }}</mat-error>
  </div>
</div>
