import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styles: [],
})
export class Page403Component {
  constructor(private router: Router) {}

  goBack() {
    this.router.navigate(['/pages/home']);
  }
}
