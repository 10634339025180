import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs';

interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styles: [''],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() active_item: string;
  items: IBreadCrumb[];
  arrayWithRoutes = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.setDataToArray();
    this.items = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.items = this.buildBreadCrumb(this.activatedRoute.root);
      });
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param items
   */
  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    items: IBreadCrumb[] = []
  ): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label =
      route.routeConfig && route.routeConfig?.data
        ? route.routeConfig?.data['breadcrumb']
        : '';
    let isClickable =
      route.routeConfig &&
      route.routeConfig.data &&
      route.routeConfig.data['isClickable'];
    let path =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();

    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label += ` / ${route.snapshot.params[paramName]}`;
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label
      ? [...items, breadcrumb]
      : [...items];

    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    let newRoutes = this.changeRoutes(newBreadcrumbs);

    return newBreadcrumbs;
  }

  changeRoutes(arrayRoutes) {
    let newArrayRoutes = arrayRoutes;

    arrayRoutes.forEach((element, index) => {
      this.arrayWithRoutes.forEach((element2, index2) => {
        if (element.url == element2.route) {
          let newProperties = {
            label: element.label,
            url: element2.newRoute,
          };
          newArrayRoutes[index] = newProperties;
        }
      });
    });

    return newArrayRoutes;
  }

  setDataToArray() {
    this.arrayWithRoutes = [
      {
        route: '/customers',
        newRoute: '/customers/customers',
      },
      {
        route: '/templates',
        newRoute: '/templates/templates',
      },
      {
        route: '/containers',
        newRoute: '/containers/search',
      },
      {
        route: '/movements',
        newRoute: '/movements/loans/consultar',
      },
      {
        route: '/auto-indexed',
        newRoute: '/auto-indexed/attach-files',
      },
      {
        route: '/users',
        newRoute: '/users/roles',
      },
      {
        route: '/settings',
        newRoute: '/settings/settings',
      },
    ];
  }
}
