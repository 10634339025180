import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '@common/services/validation.service';
import { getAlertTitles } from '@utils/constants/global-messages';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  template: '',
})
export abstract class BasePage implements OnDestroy {
  loading: boolean = false;
  $unsubscribe = new Subject<void>();
  validationService = inject(ValidationService);
  toastr = inject(ToastrService);

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  getErrorMessage(control: FormControl | AbstractControl): string {
    return this.validationService.handleError(control);
  }

  formServerErrors(
    error: HttpErrorResponse,
    form: FormGroup,
    formArray: boolean = false
  ) {
    if (!error.error?.message) {
      return;
    }
    if (!Array.isArray(error.error.message)) {
      return;
    }
    this.validationService.handleServerErrors(
      error.error.message,
      form,
      formArray
    );
  }

  async showAlert(
    icon: SweetAlertIcon,
    title: string,
    text: string,
    confirmButtonText: string = 'Aceptar'
  ) {
    return await Swal.fire({
      icon,
      title,
      text,
      showConfirmButton: true,
      confirmButtonText,
    });
  }

  async showQuestion(
    icon: SweetAlertIcon,
    title: string,
    text: string,
    confirmButtonText: string = 'Aceptar',
    cancelButtonText: string = 'Cancelar'
  ) {
    return await Swal.fire({
      icon,
      title,
      text,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    });
  }

  showToast(
    type: 'success' | 'info' | 'warning' | 'error',
    message?: string,
    _title?: string
  ) {
    const title = _title ?? getAlertTitles(type);
    this.toastr[type](message, title);
  }
}
