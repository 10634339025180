import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatherIconsModule } from '@shared/components/feather-icons/feather-icons.module';
import { MaterialModule } from '@shared/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FilterFieldComponent } from './components/filter-field/filter-field.component';
import { FiltersContainerComponent } from './components/filters-container/filters-container.component';
import { FloatFiltersComponent } from './components/float-filters/float-filters.component';

@NgModule({
  declarations: [
    FiltersContainerComponent,
    FilterFieldComponent,
    FloatFiltersComponent,
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    FeatherIconsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [FloatFiltersComponent],
})
export class FilterModule {}
