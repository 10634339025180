import { IPaginationMeta } from '@core/interfaces/pagination-response.interface';
import { DEFAULT_ITEMS_PER_PAGE } from '@utils/constants/default-pagination';

export class Pagination implements IPaginationMeta {
  itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
  totalItems: number = 0;
  currentPage: number = 1;
  totalPages: number = 1;
  sortBy: string[][] = [];
}
