import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BasePage } from '@common/base-page.component';

@Component({
  selector: 'float-filters-personalized',
  templateUrl: './float-filters.component.html',
  styles: [],
})
export class FloatFiltersPersonalizedComponent
  extends BasePage
  implements OnInit
{
  @Input() headers;
  filters = [];
  form = this.fb.group({
    operator: ['AND'],
    logicOperator: ['', [Validators.required]],
    option: ['', [Validators.required]],
    value: [''],
  });
  @Output() filterEvent: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {}

  get controls() {
    return this.form.controls;
  }

  applyFilters() {
    console.log(this.filters);
    let newFilters = [];

    if (this.filters.length > 0) {
      this.filters.forEach(element => {
        let newValue = element.value;

        if (element.logicOperator == 'LIKE') {
          newValue = '%' + newValue.toString() + '%';
        }

        newFilters.push({
          ...element,
          value: newValue,
        });
      });
    }

    console.log(newFilters);

    this.filterEvent.emit(newFilters);
  }

  removeFilter($event) {
    this.filters.find((filter, index) => {
      if (filter === $event) {
        this.filters.splice(index, 1);
      }
    });
  }

  cleanFilters() {
    this.filters = [];
    this.filterEvent.emit(this.filters);
  }

  addFilter() {
    this.form.value.value = '';

    this.form.controls.value.setValue('');

    if (!this.form.valid) {
      return;
    }

    console.log('paso');

    this.filters.push(this.form.value);

    Object.keys(this.form.controls).forEach(key => this.resetControl(key));
  }

  resetControl(key: string) {
    const control = this.form.controls[key];
    control.reset();
    control.markAsPending();
  }
}
