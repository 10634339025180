<div class="p-3">
  <h2 mat-dialog-title>
    {{ documentTypeId ? 'Editar' : 'Crear' }} Tipo de Documento
  </h2>
  <div mat-dialog-content>
    <form [formGroup]="documentTypeForm" (ngSubmit)="onSubmit()">
      <div class="row pt-2">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name" />
          <mat-error>{{ getErrorMessage(controls.name) }}</mat-error>
        </mat-form-field>
      </div>

      <div class="row pt-2">
        <ngx-select
          [formGroup]="documentTypeForm"
          control="area_id"
          [$obs]="$areas"
          appendTo="body"
          [defaultItems]="[$area]"
          bindLabel="name"
          bindValue="id"
          label="Area"></ngx-select>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Area</mat-label>
          <mat-select formControlName="area_id">
            <mat-option *ngFor="let area of areas" [value]="area.id">
              {{ area.name }}
            </mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(controls.area_id) }}</mat-error>
        </mat-form-field> -->
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div>
            <confirm-button [loading]="loading"></confirm-button>
          </div>
          <div>
            <button
              (click)="dialogRef.close()"
              type="button"
              mat-raised-button
              color="warn">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
