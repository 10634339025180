<div class="body table-responsive">
  <div class="materialTableHeader">
    <div class="row">
      <div class="col-8">
        <ul class="header-buttons-left ms-0">
          <li class="tbl-search-box">
            <label for="search-input"
              ><i class="material-icons search-icon">search</i></label
            >
            <input
              placeholder="Buscar"
              type="search"
              [formControl]="search"
              class="browser-default search-field"
              aria-label="Search box" />
          </li>
          <li class="tbl-header-btn"></li>
          <li class="tbl-header-btn"></li>
        </ul>
      </div>
      <div class="col-4">
        <ul class="tbl-export-btn">
          <!-- <li>
            <div class="export-button m-l-10" matTooltip="XLSX">
              <img src="assets/images/icons/xlsx.png" alt="" />
            </div>
          </li>
          <li>
            <div class="export-button m-l-10" matTooltip="CSV">
              <img src="assets/images/icons/csv.png" alt="" />
            </div>
          </li>
          <li>
            <div class="export-button m-l-10" matTooltip="JSON">
              <img src="assets/images/icons/json.png" alt="" />
            </div>
          </li>
          <li>
            <div class="export-button m-l-10" matTooltip="TXT">
              <img src="assets/images/icons/txt.png" alt="" />
            </div>
          </li> -->
          <li *ngIf="headers.length >= maxColumns">
            <ng-content select="columns-select"></ng-content>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <table
    class="table table-hover table-bordered"
    [ngClass]="{ loading: loading === true }">
    <thead>
      <tr>
        <th
          (click)="sort(header.column, header.sortable)"
          *ngFor="let header of headers"
          [ngClass]="{ sortable: header.sortable }"
          class="{{ getSortClass(header.column, header.sortable) }}">
          <strong>{{ header.label }}</strong>
        </th>
        <th *ngIf="actions"><strong>Acciones</strong></th>
      </tr>
    </thead>

    <ng-content select="tbody"></ng-content>
  </table>
</div>
