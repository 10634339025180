<div class="auth-container">
  <div class="row auth-main">
    <div class="col-sm-6 px-0 d-none d-sm-block">
      <div
        class="left-img"
        style="background-image: url(assets/images/pages/403.png)"></div>
    </div>
    <div class="col-sm-6 auth-form-section">
      <div class="form-section">
        <div class="auth-wrapper">
          <form>
            <span class="error-header p-b-45"> 403 </span>
            <span class="error-subheader p-b-5"> Error </span>
            <span class="error-subheader2 p-b-5">
              No tienes permisos para acceder a estos recursos
            </span>
            <div class="container-auth-form-btn mt-5">
              <button
                mat-flat-button
                color="primary"
                class="auth-form-btn"
                (click)="goBack()">
                Regresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
