import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatherIconsModule } from '@shared/components/feather-icons/feather-icons.module';
import { FilterFieldPersonalizedComponent } from '@shared/filter-personalized/components/filter-field/filter-field.component';
import { FiltersContainerPersonalizedComponent } from '@shared/filter-personalized/components/filters-container/filters-container.component';
import { FloatFiltersPersonalizedComponent } from '@shared/filter-personalized/components/float-filters/float-filters.component';
import { MaterialModule } from '@shared/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    FiltersContainerPersonalizedComponent,
    FilterFieldPersonalizedComponent,
    FloatFiltersPersonalizedComponent,
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    FeatherIconsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [FloatFiltersPersonalizedComponent],
})
export class FilterPersonalizedModule {}
