import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@core/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private authService: AuthService
  ) {}

  load() {
    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem('token');
      const isTokenExpired: boolean = this.jwtHelper.isTokenExpired(token);
      if (!token || isTokenExpired) {
        localStorage.clear();
        this.router.navigate(['/auth/signin']);
        return resolve(true);
      }
      const config = await this.authService.configUser();
      if (!config) {
        localStorage.clear();
        this.router.navigate(['/auth/signin']);
        return resolve(true);
      }
      return resolve(true);
    });
  }
}
