import { Injectable } from '@angular/core';
import { Repository } from '@core/repository/repository';
import { ISettings } from '@subSettings/interfaces/settings.interface';

@Injectable({ providedIn: 'root' })
export class SettingsService extends Repository<ISettings> {
  constructor() {
    super();
    this.path = 'settings';
  }

  uploadLogoPicture(formData: FormData, id: number) {
    return this.httpClient.post(
      `${this.route}${this.path}/settings-logo/${id}`,
      formData
    );
  }
}
