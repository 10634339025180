import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AllowedFilesService } from '@core/service/allowed-files.service';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: 'input[type=file]',
})
export class FileFilterDirective {
  @Input() filter: boolean = true;

  constructor(
    private el: ElementRef,
    private allowedFilesService: AllowedFilesService,
    private toastr: ToastrService
  ) {}

  @HostListener('change') onChange() {
    console.log(this.filter);
    if (!this.filter) {
      return;
    }
    const allowedExtensions = this.getAllowedExtensions();
    const fileInput = this.el.nativeElement as HTMLInputElement;
    const files = Array.from(fileInput.files);
    const validFiles = files.filter(file => {
      const fileExtension = file.name.split('.').pop() ?? '';
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        this.showError(fileExtension);
      }
      return allowedExtensions.includes(fileExtension.toLowerCase());
    });
    console.log(validFiles);
    if (!validFiles.length) {
      fileInput.value = '';
    } else {
      const fileList = new DataTransfer();
      validFiles.forEach(file => {
        fileList.items.add(file);
      });
      fileInput.files = fileList.files as any;
    }
  }

  private showError(extension: string) {
    this.toastr.error(
      `No tiene permisos para subir archivos .${extension.toLowerCase()}`,
      'Error'
    );
  }

  private getAllowedExtensions() {
    const extensions = this.allowedFilesService.getExtensions();
    return extensions.map(extension => extension.toLowerCase());
  }
}
