import { CommonModule } from '@angular/common';
import { forwardRef, NgModule } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccountLevelsFormComponent } from '@shared/components/account-levels-form/account-levels-form.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { ConfirmButtonComponent } from '@shared/components/confirm-button/confirm-button.component';
import { ConfirmDeleteComponent } from '@shared/components/confirm-delete/confirm-delete.component';
import { ContentBodyComponent } from '@shared/components/content-body/content-body.component';
import { FeatherIconsModule } from '@shared/components/feather-icons/feather-icons.module';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { FormFieldComponent } from '@shared/components/form-field/form-field.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { TableComponent } from '@shared/components/table/table.component';
import { FileFilterDirective } from '@shared/directives/file-filter.directive';
import { UppercaseDirective } from '@shared/directives/uppercase.directive';
import { FilterPersonalizedModule } from '@shared/filter-personalized/filter.module';
import { FilterModule } from '@shared/filter/filter.module';
import { MaterialModule } from '@shared/material/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ColumnsSelectComponent } from './components/columns-select/columns-select.component';
import { SelectComponent } from './components/select/select.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    BreadcrumbComponent,
    ContentBodyComponent,
    TableComponent,
    ConfirmButtonComponent,
    PaginationComponent,
    FormFieldComponent,
    ConfirmDeleteComponent,
    ColumnsSelectComponent,
    UppercaseDirective,
    AccountLevelsFormComponent,
    FileFilterDirective,
    SelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FeatherIconsModule,
    MaterialModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    MatDatepickerModule,
    NgScrollbarModule,
    FilterModule,
    NgxPermissionsModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    FileUploadComponent,
    BreadcrumbComponent,
    FileUploadComponent,
    BreadcrumbComponent,
    ContentBodyComponent,
    TableComponent,
    ConfirmButtonComponent,
    PaginationComponent,
    FormFieldComponent,
    ConfirmDeleteComponent,
    ColumnsSelectComponent,
    MatRadioModule,
    UppercaseDirective,
    AccountLevelsFormComponent,
    MatTooltipModule,
    MatDatepickerModule,
    FilterModule,
    FilterPersonalizedModule,
    NgxPermissionsModule,
    FileFilterDirective,
    SelectComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SharedModule {}
