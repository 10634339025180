export const ALL_FILTER_OPERATORS = [
  {
    operator: '$eq',
    symbol: '=',
  },
  {
    operator: '$gt',
    symbol: '>',
  },
  {
    operator: '$gte',
    symbol: '>=',
  },
  {
    operator: '$lt',
    symbol: '<',
  },
  {
    operator: '$lte',
    symbol: '<=',
  },
  {
    operator: '$ilike',
    symbol: '%',
  },
];

export const TEXT_FILTER_OPERATORS = [
  {
    operator: '$eq',
    symbol: '=',
  },
  {
    operator: '$ilike',
    symbol: '%',
  },
];

export const NUMBER_FILTER_OPERATORS = [
  {
    operator: '$eq',
    symbol: '=',
  },
  {
    operator: '$gt',
    symbol: '>',
  },
  {
    operator: '$gte',
    symbol: '>=',
  },
  {
    operator: '$lt',
    symbol: '<',
  },
  {
    operator: '$lte',
    symbol: '<=',
  },
];

export const DATE_FILTER_OPERATORS = [
  {
    operator: '$eq',
    symbol: '=',
  },
  {
    operator: '$gt',
    symbol: '>',
  },
  {
    operator: '$gte',
    symbol: '>=',
  },
  {
    operator: '$lt',
    symbol: '<',
  },
  {
    operator: '$lte',
    symbol: '<=',
  },
];
