'use strict';

module.exports = {
  development: {
    production: false,
    apiUrl: 'https://centraldoc.mx',
    API_URL: 'https://back.centraldoc.mx/api/',
    API_VERSION: 'v1',
    SOCKET_IO_URL: 'https://py.centraldoc.mx',
    JWT_ALLOWED_DOMAINS: [
      'back.centraldoc.mx',
      'https://back.centraldoc.mx',
      'py.centraldoc.mx',
      'https://py.centraldoc.mx',
    ],
  },
  staging: {
    production: false,
    apiUrl: 'https://capital.slide-dev.com',
    API_URL: 'https://capital-back.slide-dev.com/api/',
    API_VERSION: 'v1',
    SOCKET_IO_URL: 'https://capital-back.slide-dev.com',
    JWT_ALLOWED_DOMAINS: [
      'localhost:3000',
      'capital-back.slide-dev.com',
      'https://capital-back.slide-dev.com',
      'back.centraldoc.mx',
      'https://back.centraldoc.mx',
    ],
  },
  production: {
    production: true,
    apiUrl: 'https://centraldoc.mx',
    API_URL: 'https://back.centraldoc.mx/api/',
    API_VERSION: 'v1',
    SOCKET_IO_URL: 'https://py.centraldoc.mx',
    JWT_ALLOWED_DOMAINS: [
      'capital-back.slide-dev.com',
      'https://capital-back.slide-dev.com',
      'back.centraldoc.mx',
      'py.centraldoc.mx'
    ],
  },
};
