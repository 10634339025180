import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { Page403Component } from '@auth/page403/page403.component';
import { Page404Component } from '@auth/page404/page404.component';
import { AuthGuard } from '@core/guard/auth.guard';
import { LoginGuard } from '@core/guard/login.guard';
import { PageTitleStrategy } from '@core/strategies/page-title-strategy.service';
import { AuthLayoutComponent } from '@layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@layout/app-layout/main-layout/main-layout.component';
const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canMatch: [LoginGuard],
    loadChildren: async () =>
      (await import('./authentication/authentication.module'))
        .AuthenticationModule,
  },
  {
    path: 'pages',
    component: MainLayoutComponent,
    canMatch: [AuthGuard],
    loadChildren: async () =>
      (await import('./pages/pages.module')).PagesModule,
  },
  { path: '', redirectTo: '/pages/containers/search', pathMatch: 'full' },
  { path: '404', component: Page404Component },
  { path: '403', component: Page403Component },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
