import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BasePage } from '@common/base-page.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor extends BasePage implements HttpInterceptor {
  constructor(private router: Router, private matDialog: MatDialog) {
    super();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 403) {
          this.matDialog.closeAll();
          this.showAlert(
            'error',
            'Prohibido',
            'No tienes permisos para realizar esta acción'
          );
        }
        return throwError(() => err);
      })
    );
  }
}
