import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ITableHeader,
  TableHeader,
} from '@shared/interfaces/table-header.interface';

@Component({
  selector: 'columns-select',
  templateUrl: './columns-select.component.html',
  styles: [
    `
      /* button { */
      :host ::ng-deep span.mdc-list-item__primary-text {
        width: 100% !important;
      }
      /* } */
    `,
  ],
})
export class ColumnsSelectComponent implements OnInit {
  @Input() headers: TableHeader[] = [];
  @Output() headersChange = new EventEmitter<TableHeader[]>();
  @Input() defaultColumns = 5;
  @Input() minHeaders = 3;
  allHeaders: TableHeader[] = [];
  get visibleHeaders() {
    return this.allHeaders.filter(header => header.show);
  }

  ngOnInit(): void {
    this.allHeaders = [...this.headers];
    setTimeout(() => {
      this.buildVisibleColumns();
    }, 0);
  }

  private buildVisibleColumns() {
    this.allHeaders.forEach((header, index) => {
      header.show =
        header.showAlways == true
          ? true
          : index < this.defaultColumns
          ? true
          : false;
    });
    this.filterHeaders();
  }

  private filterHeaders() {
    this.headersChange.emit(this.visibleHeaders);
  }

  selectColumn(event: Event, header: ITableHeader) {
    event.stopPropagation();
    header.show = !header.show;
    this.filterHeaders();
  }
}
