import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationResponse } from '@core/interfaces/pagination-response.interface';
import { Repository } from '@core/repository/repository';
import { ICustomer } from '@customer/customers/interfaces/customer.interface';

@Injectable({ providedIn: 'root' })
export class CustomersService extends Repository<ICustomer> {
  constructor() {
    super();
    this.path = 'customers';
  }

  getByUser(params?: HttpParams) {
    const url = 'by-user';
    return this.get<IPaginationResponse<ICustomer>>({ url, params });
  }

  uploadProfilePicture(formData: FormData, id: number) {
    return this.httpClient.post(
      `${this.route}${this.path}/customer-picture/${id}`,
      formData
    );
  }
}
