import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanMatch {
  routeSafe: string = '/pages/perfil';
  canEntry: any;

  constructor(private router: Router, private jwtHelper: JwtHelperService) {}

  async canMatch(route: Route, segments: UrlSegment[]) {
    return this.isUserSgnedIn();
  }

  private isUserSgnedIn() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/auth/signin']);
      return false;
    }

    if (!this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    this.router.navigate(['/auth/signin']);
    return false;
  }
}
