<div>
  <h2 mat-dialog-title>¿Estas seguro?</h2>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between">
        <div class="px-2">
          <button mat-button (click)="dialogRef.close()" mat-dialog-close>
            Cancelar
          </button>
        </div>
        <div class="px-2">
          <confirm-button
            (confirm)="confirm()"
            [loading]="loading"
            text="Borrar"
            loadingText="Borrando"></confirm-button>
        </div>
      </div>
    </div>
  </div>
</div>
