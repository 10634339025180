<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb [title]="title"> </app-breadcrumb>
    </div>
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header w-100">
            <ng-content select="[header]"></ng-content>
          </div>
          <div class="body">
            <ng-content select="[body]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
