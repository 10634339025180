import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasePage } from '@common/base-page.component';
import { CustomersService } from '@customer/customers/service/customers.service';
import { CustomerForm } from '@customer/customers/utils/customer-form';
import { ICustomer } from '@customers/interfaces/customer.interface';
import { environment } from '@environments/environment';
import { RECORD_SAVED } from '@utils/constants/global-messages';
import { catchError, switchMap, throwError } from 'rxjs';

interface DialogData {
  id: number;
}
@Component({
  selector: 'app-customers-form',
  templateUrl: './customers-form.component.html',
  styles: [],
})
export class CustomersFormComponent extends BasePage implements OnInit {
  customerForm = this.fb.group(new CustomerForm());
  readonly customerId: number = null;
  cleanURL: string;
  imgSrc;
  imgName;
  file = new FormControl(null);

  get controls() {
    return this.customerForm.controls;
  }
  constructor(
    private fb: FormBuilder,
    private customersService: CustomersService,
    public dialogRef: MatDialogRef<CustomersFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
    this.customerId = this.data.id;
  }

  ngOnInit(): void {
    this.cleanURL =
      environment.API_URL + environment.API_VERSION + '/files/customers/img/';

    this.imgSrc = this.cleanURL + 'empresa.png';

    if (this.customerId) {
      this.getcustomer();
    }
  }

  getcustomer() {
    this.customersService.getById(this.customerId).subscribe({
      next: item => {
        this.imgSrc = this.cleanURL + item.img;

        this.imgName = item.img;

        this.customerForm.patchValue({
          ...item,
          img: null,
        });
      },
    });
  }

  onSubmit() {
    if (!this.customerForm.valid) {
      return;
    }
    this.customerId ? this.update() : this.save();
  }

  save() {
    let customerForm = this.customerForm.value;

    if (customerForm.img == null) {
      return this.customersService.create(this.customerForm.value).subscribe({
        next: customer => this.handleSuccess(customer),
        error: error => this.handleError(error),
      });
    } else {
      this.customersService
        .create(this.customerForm.value)
        .pipe(switchMap(customer => this.updateProfilePicture(customer.id)))
        .subscribe({
          next: () => this.handleSuccess(),
          error: error => this.handleError(error),
        });
    }
  }

  updateProfilePicture(customerID) {
    let form = new FormData();

    form.append('photo', this.file.value);

    return this.customersService.uploadProfilePicture(form, customerID).pipe(
      catchError(error => {
        this.loading = false;
        return throwError(() => error);
      })
    );
  }

  update() {
    let customerForm = this.customerForm.value;

    customerForm.img = this.imgName;

    if (this.file.value == null) {
      return this.customersService
        .update(this.customerId, this.customerForm.value)
        .subscribe({
          next: () => this.handleSuccess(),
          error: error => this.handleError(error),
        });
    } else {
      this.customersService
        .update(this.customerId, this.customerForm.value)
        .pipe(switchMap(customer => this.updateProfilePicture(this.customerId)))
        .subscribe({
          next: () => this.handleSuccess(),
          error: error => this.handleError(error),
        });
    }
  }

  handleError(error: HttpErrorResponse) {
    this.loading = false;
    this.formServerErrors(error, this.customerForm);
  }

  handleSuccess(customer?: ICustomer) {
    this.showToast('success', RECORD_SAVED);
    this.loading = false;
    this.dialogRef.close(customer ?? true);
  }

  fileInput(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = e => (this.imgSrc = reader.result);
      reader.readAsDataURL(file);
    }
    this.file.setValue(target.files[0]);
  }
}
