import { Component, ElementRef } from '@angular/core';
import { RightSidebarService } from '@core/service/rightsidebar.service';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';

@Component({
  selector: 'filters-container-personalized',
  templateUrl: './filters-container.component.html',
  styles: [],
})
export class FiltersContainerPersonalizedComponent extends UnsubscribeOnDestroyAdapter {
  maxHeight: string;
  maxWidth: string;
  isOpenSidebar: boolean;
  public innerHeight: any;
  headerHeight = 600;

  constructor(
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService
  ) {
    super();
  }
  ngOnInit() {
    this.setRightSidebarWindowHeight();
  }

  setRightSidebarWindowHeight() {
    this.innerHeight = window.innerHeight;
    const height = 750;
    this.maxHeight = height + '';
    this.maxWidth = '600px';
  }
  onClickedOutside(event: Event) {
    const button = event.target as HTMLButtonElement;
    if (button.id !== 'settingBtn') {
      if (this.isOpenSidebar === true) {
        this.toggleRightSidebar();
      }
    }
  }
  toggleRightSidebar(): void {
    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }
}
