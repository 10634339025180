import { PlatformLocation } from '@angular/common';
import { Component } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Socket } from 'ngx-socket-io';
import { tap } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl: string;
  constructor(
    public _router: Router,
    location: PlatformLocation,
    private ngSelectConfig: NgSelectConfig,
    private socket: Socket
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });

    this.ngSelectConfig.notFoundText = 'No se encontraron items';
    this.ngSelectConfig.loadingText = 'Cargando...';
  }

  ngOnInit() {
    this.socket
      .fromEvent('mensaje')
      .pipe(
        tap(x => {
          console.log(x);
        })
      )
      .subscribe();
  }
}
