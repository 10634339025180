import { Injectable } from '@angular/core';
import { Repository } from '@core/repository/repository';
import { IBranch } from '@customer/branches/interfaces/branch.interface';

@Injectable({ providedIn: 'root' })
export class BranchService extends Repository<IBranch> {
  constructor() {
    super();
    this.path = 'branches';
  }
}
