<filters-container>
  <ng-container *ngFor="let header of filterableHeaders">
    <filter-field [header]="header"></filter-field>
  </ng-container>

  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="applyFilters()">
          Buscar <mat-icon>search</mat-icon>
        </button>
      </div>

      <div>
        <button
          (click)="cleanFilters()"
          mat-raised-button
          color="secondary"
          type="button">
          Limpiar <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</filters-container>
