<div class="breadcrumb-main">
  <div class="row">
    <div class="col-6">
      <div class="breadcrumb-title">
        <h4 class="page-title">{{ title }}</h4>
      </div>
    </div>
    <div class="col-6">
      <ul class="breadcrumb-list">
        <li class="breadcrumb-item bcrumb-1">
          <a [routerLink]="''">
            <app-feather-icons
              [icon]="'home'"
              [class]="'breadcrumb-icon'"></app-feather-icons>
          </a>
        </li>

        <li class="breadcrumb-item" *ngFor="let item of items">
          <a
            [routerLink]="'/pages/' + item.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
