import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OcrSocketService {
  $subscriber = new Subject<{ id; files: string[] }>();
  constructor(private socket: Socket) {}

  uploadOcr(id: string) {
    return this.socket.fromEvent(id).pipe(tap(status => {}));
  }
}
