import { Component, ContentChild, ViewChild } from '@angular/core';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styles: [],
})
export class FormFieldComponent {
  isBeforeViewInit$$ = new BehaviorSubject(true);
  @ContentChild(MatFormFieldControl)
  matFormControl: MatFormFieldControl<unknown>;
  @ViewChild(MatFormField) matFormField: MatFormField;

  ngAfterViewInit() {
    // replace the reference to the dummy control
    this.matFormField._control = this.matFormControl;
    // force the form field to rebind everything to the actual control
    this.matFormField.ngAfterContentInit();
    this.isBeforeViewInit$$.next(false);
  }
}
