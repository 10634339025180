import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BasePage } from '@common/base-page.component';
import { AreasFormComponent } from '@customer/areas/components/areas-form/areas-form.component';
import { IArea } from '@customer/areas/interfaces/area.interface';
import { AreasService } from '@customer/areas/service/areas.service';
import { CustomersFormComponent } from '@customers/components/customers-form/customers-form.component';
import { ICustomer } from '@customers/interfaces/customer.interface';
import { CustomersService } from '@customers/service/customers.service';
import { DocumentTypesFormComponent } from '@pages/templates/document-types/components/document-types-form/document-types-form.component';
import { IDocumentType } from '@pages/templates/document-types/interfaces/document-type.interface';
import { DocumentTypesService } from '@pages/templates/document-types/service/document-types.service';
import { AccountLevelsForm } from '@shared/utils/account-levels-form';
import { SearchParams } from '@utils/classes/list-params';
import { BehaviorSubject, of, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'account-levels-form',
  templateUrl: './account-levels-form.component.html',
  styles: [
    `
      .level {
        min-width: 85%;
        margin-right: 10px;
      }

      .delMrg > div {
        margin-bottom: 0px;
        margin-top: 2%;
      }
    `,
  ],
})
export class AccountLevelsFormComponent extends BasePage implements OnInit {
  customers: ICustomer[] = [];
  areas: IArea[] = [];
  documentTypes: IDocumentType[] = [];
  accountLevelForm = this.fb.group(new AccountLevelsForm());
  @Input() addLevels: boolean = true;
  $customers = this.customersService.getAllPaginated.bind(
    this.customersService
  );
  $areas = this.areasService.getByUser.bind(this.areasService);
  $documentTypes = this.documentTypesService.findByArea.bind(
    this.documentTypesService
  );
  $customersParams = new BehaviorSubject(new SearchParams());
  $areasParams = new BehaviorSubject(new SearchParams());
  $documentTypesParams = new BehaviorSubject(new SearchParams());
  customerId: number;
  areaId: number;
  documentTypeId: number;
  $newCustomer: ICustomer = null;
  $area: IArea = null;
  $documentType: IDocumentType = null;

  get controls() {
    return this.accountLevelForm.controls;
  }

  @Output() customerChange = new EventEmitter<number>();
  @Output() areaChange = new EventEmitter<number>();
  @Output() documentTypeChange = new EventEmitter<number>();

  constructor(
    private fb: FormBuilder,
    private customersService: CustomersService,
    private areasService: AreasService,
    private documentTypesService: DocumentTypesService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCustomers().subscribe();
    this.onCustomerChange().subscribe();
    this.onAreaChange().subscribe();
    this.onDocumentTypeChage().subscribe();
  }

  getCustomers() {
    return this.customersService.getByUser().pipe(
      tap(response => {
        {
          this.customers = response.data;
          if (response.meta.totalItems === 0) {
            this.showToast('warning', 'No hay cuentas nivel 1 asignadas');
          }
        }
      })
    );
  }

  onCustomerChange() {
    const { area, documentType, customer } = this.controls;
    return customer.valueChanges.pipe(
      takeUntil(this.$unsubscribe),
      tap(() => {
        area.reset();
        this.areas = [];
        documentType.reset();
        this.documentTypes = [];
      }),
      switchMap(customerId => {
        this.customerId = customerId;
        this.customerChange.emit(this.customerId);
        return customerId ? this.getAreas(customerId) : of();
      })
    );
  }

  getAreas(customerId: number) {
    return this.areasService.getByUser(customerId).pipe(
      tap((response: any) => {
        this.areas = response;
        if (response.length === 0) {
          this.showToast('warning', 'No hay cuentas nivel 2 asignadas');
        }
      })
    );
  }

  onAreaChange() {
    const { area, documentType } = this.controls;
    return area.valueChanges.pipe(
      takeUntil(this.$unsubscribe),
      tap(() => documentType.reset()),
      switchMap(areaId => {
        this.areaId = areaId;
        this.areaChange.emit(this.areaId);
        return areaId ? this.getDocumentTypes(areaId) : of();
      })
    );
  }

  getDocumentTypes(areaId: number) {
    return this.documentTypesService.findByUser(areaId).pipe(
      tap((response: any) => {
        this.documentTypes = response;
        if (response.length === 0) {
          this.showToast('warning', 'No hay cuentas nivel 3 asignadas');
        }
      })
    );
  }

  onDocumentTypeChage() {
    const { documentType } = this.controls;
    return documentType.valueChanges.pipe(
      takeUntil(this.$unsubscribe),
      tap(documentType => this.documentTypeChange.emit(documentType))
    );
  }

  openCustomerForm(id?: number) {
    const dialogRef = this.dialog.open(CustomersFormComponent, {
      data: { id },
    });
    dialogRef.afterClosed().subscribe({
      next: customer => {
        if (customer) {
          this.$newCustomer = customer;
          this.controls.customer.setValue(customer.id);
        }
      },
    });
  }

  openAreaForm(id?: number) {
    const dialogRef = this.dialog.open(AreasFormComponent, {
      data: { id: id, customerId: this.customerId },
    });
    dialogRef.afterClosed().subscribe({
      next: area => {
        if (area) {
          this.$area = area;
          this.controls.area.setValue(area.id);
        }
      },
    });
  }

  openDocumentTypeForm(id?: number) {
    const dialogRef = this.dialog.open(DocumentTypesFormComponent, {
      data: { id: id, areaId: this.areaId, clienteId: this.customerId },
    });
    dialogRef.afterClosed().subscribe({
      next: documentType => {
        if (documentType) {
          this.$documentType = documentType;
          this.controls.documentType.setValue(documentType.id);
        }
      },
    });
  }
}
