<div class="p-3">
  <h2 mat-dialog-title>{{ customerId ? 'Editar' : 'Crear' }} Cliente</h2>
  <div mat-dialog-content>
    <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
      <div class="d-flex justify-content-between align-items-center pb-3">
        <div>
          <img
            [src]="imgSrc"
            alt="Empresa"
            width="100px"
            height="100px"
            style="border-radius: 50%; border: 1px solid #ccc; padding: 5px"
            class="p-1" />
        </div>
        <input
          type="file"
          id="imageUpload"
          formControlName="img"
          [filter]="false"
          accept=".png, .jpg, .jpeg"
          (change)="fileInput($event)" />
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Nombre de la empresa</mat-label>
            <input matInput formControlName="first_name" />
            <mat-icon matSuffix>face</mat-icon>
            <mat-error>{{ getErrorMessage(controls.first_name) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Correo</mat-label>
            <input matInput formControlName="email" />
            <mat-icon matSuffix>email</mat-icon>
            <mat-error>{{ getErrorMessage(controls.email) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="phone" />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-error>{{ getErrorMessage(controls.phone) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Dirección</mat-label>
            <textarea matInput formControlName="address"></textarea>
            <mat-icon matSuffix>location_on</mat-icon>
            <mat-error>{{ getErrorMessage(controls.address) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Ciudad</mat-label>
            <input matInput formControlName="city" />
            <mat-icon matSuffix>location_city</mat-icon>
            <mat-error>{{ getErrorMessage(controls.city) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Estado</mat-label>
            <input matInput formControlName="state" />
            <mat-icon matSuffix>business</mat-icon>
            <mat-error>{{ getErrorMessage(controls.state) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Pais</mat-label>
            <mat-select formControlName="country">
              <mat-option [value]="'Mexico'"> Mexico </mat-option>
            </mat-select>
            <mat-icon matSuffix>flag</mat-icon>
            <mat-error>{{ getErrorMessage(controls.country) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div>
            <confirm-button [loading]="loading"></confirm-button>
          </div>
          <div>
            <button
              (click)="dialogRef.close()"
              type="button"
              mat-raised-button
              color="warn">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
