import { FormControl, Validators } from '@angular/forms';

export class CustomerForm {
  first_name = new FormControl<string>(null, [Validators.required]);
  phone = new FormControl<string>(null, []);
  email = new FormControl<string>(null, [Validators.email]);
  img = new FormControl(null, []);
  address = new FormControl<string>(null, []);
  city = new FormControl<string>(null, []);
  state = new FormControl<string>(null, []);
  country = new FormControl<string>(null, []);
}
