<div>
  <!-- Left Sidebar -->
  <aside
    id="leftsidebar"
    class="sidebar"
    (mouseenter)="mouseHover($event)"
    (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <li class="sidebar-user-panel">
            <div class="user-panel">
              <div class="image">
                <img
                  [src]="userImg"
                  class="img-circle user-img-circle"
                  alt="User Image" />
              </div>
            </div>
            <div class="profile-usertitle">
              <div class="sidebar-userpic-name">{{ userFullName }}</div>
              <div class="profile-usertitle-job">{{ userRole }}</div>
            </div>
          </li>
          <!-- Top Most level menu -->
          <li
            *ngFor="let sidebarItem of $menuItems | async"
            [routerLinkActive]="
              sidebarItem.submenu.length != 0 ? 'active' : 'active-top'
            ">
            <!-- <div class="header" *ngIf="sidebarItem.groupTitle === true">
              {{ sidebarItem.title }}
            </div> -->
            <a
              [routerLink]="
                sidebarItem.submenu.length > 0 ? null : [sidebarItem.path]
              "
              (click)="callToggleMenu($event, sidebarItem.submenu.length)"
              class="menu-top"
              [ngClass]="[sidebarItem.class]">
              <mat-icon class="sidebar-icon">{{ sidebarItem.icon }}</mat-icon>
              <span class="hide-menu">{{ sidebarItem.title }} </span>
              <!-- <span
                *ngIf="sidebarItem.badge != ''"
                [ngClass]="[sidebarItem.badgeClass]"
                >{{ sidebarItem.badge }}</span
              > -->
            </a>
            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li
                *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                [routerLinkActive]="
                  sidebarSubItem1.submenu.length > 0 ? '' : 'active'
                ">
                <a
                  [routerLink]="
                    sidebarSubItem1.submenu.length > 0
                      ? null
                      : [sidebarSubItem1.path]
                  "
                  (click)="
                    callToggleMenu($event, sidebarSubItem1.submenu.length)
                  "
                  [ngClass]="[sidebarSubItem1.class]">
                  {{ sidebarSubItem1.title }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
