<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"></a>

      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"></a>

      <a class="navbar-brand" routerLink="{{ homePage }}">
        <div class="logo">
          <img [src]="logoImg" alt="Logo" class="logoImg" />
        </div>
      </a>
    </div>
    <div
      class="collapse navbar-collapse"
      [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse nav-notification-icons">
            <app-feather-icons
              [icon]="'menu'"
              [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item btnNotification" ngbDropdown>
          <button
            #notificationsButton
            mat-button
            [matMenuTriggerFor]="notificationMenu"
            class="nav-notification-icons">
            <app-feather-icons
              [icon]="'bell'"
              [class]="'header-icon'"></app-feather-icons>
            <span class="label-count bg-orange"></span>
          </button>

          <mat-menu
            #notificationMenu="matMenu"
            class="nfc-menu"
            xPosition="before">
            <div class="nfc-header">
              <h5 class="mb-0">Notificaciones</h5>
            </div>
            <div class="nfc-dropdown" (click)="$event.stopPropagation()">
              <ng-scrollbar
                style="height: 350px"
                visibility="hover"
                (click)="$event.stopPropagation()">
                <div class="noti-list header-menu">
                  <div class="menu" (click)="$event.stopPropagation()">
                    <div
                      mat-menu-item
                      *ngFor="let notification of notifications; let i = index">
                      <span class="table-img msg-user">
                        <i class="material-icons-two-tone nfc-type-icon">{{
                          notification.icon
                        }}</i>
                      </span>
                      <span class="menu-info">
                        <span class="menu-title">{{ notification.name }}</span>
                        <span class="menu-desc mt-2">
                          <div class="col-md-8">
                            <mat-progress-bar
                              [color]="
                                notification.status == 'error'
                                  ? 'warn'
                                  : 'primary'
                              "
                              [mode]="notification.mode"
                              [value]="
                                notification.progress
                              "></mat-progress-bar>
                          </div>
                          {{ notification.message }}
                        </span>
                      </span>
                      <span
                        class="nfc-close"
                        (click)="
                          $event.stopPropagation(); removeNotification(i)
                        ">
                        <app-feather-icons
                          [icon]="'x'"
                          [class]="'user-menu-icons'"></app-feather-icons>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>

            <div class="nfc-footer">
              <a (click)="removeAllNotifications()">Read All Notifications</a>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <span>{{ userFullName }}</span>
              <img
                [src]="userImg"
                class="user_img"
                width="32"
                height="32"
                alt="User" />
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button mat-menu-item (click)="goToProfile()">
                    <app-feather-icons
                      [icon]="'user'"
                      [class]="'user-menu-icons'"></app-feather-icons>
                    Ir a Perfil
                  </button>
                  <button mat-menu-item (click)="goToSettings()">
                    <app-feather-icons
                      [icon]="'settings'"
                      [class]="'user-menu-icons'"></app-feather-icons>
                    Ir a Configuraciónes
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons
                      [icon]="'log-out'"
                      [class]="'user-menu-icons'"></app-feather-icons>
                    Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
