import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BasePage } from '@common/base-page.component';
import { Pagination } from '@core/classes/pagination';
import {
  IPaginationMeta,
  IPaginationResponse,
} from '@core/interfaces/pagination-response.interface';
import { SearchParams } from '@utils/classes/list-params';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  Observable,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'ngx-select',
  templateUrl: './select.component.html',
  styles: [
    `
      mat-error {
        -webkit-font-smoothing: antialiased;
        font-family: var(
          --mdc-typography-caption-font-family,
          var(--mdc-typography-font-family, Roboto, sans-serif)
        );
        font-size: var(--mdc-typography-caption-font-size, 12px);
        line-height: var(--mdc-typography-caption-line-height, 20px);
        font-weight: var(--mdc-typography-caption-font-weight, 400);
        letter-spacing: var(
          --mdc-typography-caption-letter-spacing,
          0.0333333333em
        );

        padding-left: 16px;
        padding-right: 16px;
      }

      .error-container {
        min-height: 22px;
      }

      .focus + label {
        font-size: 12px;
        color: #afbdcf;
        top: -5px;
        left: 10px;
        background: #ffffff;
        padding: 0px 5px 0px 5px;
      }
    `,
  ],
})
export class SelectComponent extends BasePage implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() control: string;
  @Input() label: string = '';
  @Input() $obs: (...args: any[]) => Observable<IPaginationResponse<any>>;
  @Input() args: any[] = [];
  @Input() bindLabel: string = '';
  @Input() bindValue: string = '';
  @Input() appendTo: string = '';
  @Input() params = new BehaviorSubject(new SearchParams());
  @Input() defaultItems: any[] = [];
  // variables que utilizo especificamente en la creacion de prestamos
  @Input() deleteItems: boolean = false;
  @Output() valueSelected: any;
  @Output() aVAlueIsSelected = new EventEmitter<any>();
  itemsDeleted: any[] = [];

  $input = new Subject<string>();
  items: any[] = [];
  pagination: IPaginationMeta = new Pagination();
  concat = true;
  hasBeenClosed = false;
  focus = false;
  blur = false;
  private currentPage = 1;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultItems']) {
      this.setItems(this.items);
    }

    if (changes['args']) {
      const params = this.params.getValue();
      this.params.next(params);
    }
  }

  ngOnInit() {
    this.onSearch().subscribe();
    this.params
      .pipe(
        takeUntil(this.$unsubscribe),
        tap(params => {
          this.concat = this.currentPage < params.page;
          if (this.args.filter(arg => arg).length != this.args.length) {
            return;
          }
          this.getItems(params).subscribe();
        })
      )
      .subscribe();
  }

  onClear() {
    this.params.next(new SearchParams());
  }

  onSearch() {
    return this.$input.pipe(
      takeUntil(this.$unsubscribe),
      debounceTime(1000),
      distinctUntilChanged(),
      tap(search => {
        if (this.hasBeenClosed) {
          this.hasBeenClosed = false;
          return;
        }
        const params = this.params.getValue();
        params.page = 1;
        params.search = search ?? '';
        this.params.next(params);
      })
    );
  }

  getItems(params: SearchParams) {
    this.loading = true;
    return this.$obs(...this.args, params.getParams()).pipe(
      tap(response => {
        this.setItems(response.data);
        this.pagination = response.meta;
      }),
      finalize(() => (this.loading = false))
    );
  }

  private setItems(items: any[]) {
    this.items = this.concat ? [...this.items, ...items] : items;
    if (this.defaultItems.length) {
      const defaultItems = this.defaultItems.filter(i => i);
      this.items = [...defaultItems, ...this.items];
      this.defaultItems = [];
    }
    this.items = this.removeDuplicates(this.items);
  }

  private removeDuplicates(array: any[]) {
    const uniqueArray = array.filter(
      (item, index, self) =>
        index ===
        self.findIndex(t => t[this.bindValue] === item[this.bindValue])
    );
    return uniqueArray;
  }

  nextPage() {
    const params = this.params.getValue();
    if (params.page < this.pagination.totalPages) {
      params.page++;
      this.params.next(params);
    }
  }

  isInvalid(): boolean {
    return this.validationService.isInvalid(this.formGroup.get(this.control));
  }

  invalidClass(): string {
    return this.validationService.invalidClass(
      this.formGroup.get(this.control)
    );
  }

  getErrorMessage(): string {
    return this.validationService.handleError(this.formGroup.get(this.control));
  }

  isRequired() {
    return this.formGroup.get(this.control).hasValidator(Validators.required);
  }

  setValueToOutput($event) {
    let value = $event[this.bindValue];

    if (!value) {
      console.error('No se encontro el valor del bindValue');
    }

    this.valueSelected = value;

    this.aVAlueIsSelected.emit($event);
  }

  restoreValue(id) {
    let itemToRestore = this.itemsDeleted.find(
      item => item[this.bindValue] == id
    );

    this.itemsDeleted = this.itemsDeleted.filter(
      item => item[this.bindValue] != id
    );

    this.items.unshift(itemToRestore);
  }

  deleteSelectValue() {
    if (this.deleteItems) {
      let itemToDeleted = this.items.find(
        item => item[this.bindValue] == this.valueSelected
      );
      this.itemsDeleted.push(itemToDeleted);

      this.items = this.items.filter(
        item => item[this.bindValue] != this.valueSelected
      );

      // TODO: cambiar el valor actual del select por el siguiente
      this.valueSelected = null;

      this.aVAlueIsSelected.emit(null);
    }
  }
}
