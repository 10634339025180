import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IPaginationMeta } from '@core/interfaces/pagination-response.interface';
import { SearchParams } from '@utils/classes/list-params';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styles: [
    `
      .custom-pagination {
        display: inline-block;
      }

      .custom-pagination li {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color 0.3s;
        border: 1px solid #ddd;
        margin: 0 4px;
        cursor: default;
        border-radius: 5px;
      }

      .custom-pagination li.active {
        background-color: #6777ef;
        color: white;
        border: 1px solid #6777ef;
        border-radius: 5px;
      }

      .custom-pagination li:hover:not(.active) {
        background-color: #ddd;
        border-radius: 5px;
      }
    `,
  ],
})
export class PaginationComponent implements OnInit, OnChanges {
  /**
   * @param {IPagination} pagination - Recive los parametros de la paginación
   * @param {EventEmitter} changePage - Emite el evento al componente padre cuando se cambia de pagina
   */

  @Input() pagination: IPaginationMeta;
  @Input() params: BehaviorSubject<SearchParams> = new BehaviorSubject(
    new SearchParams()
  );
  @Output() changePage = new EventEmitter<number>();
  pages: any[] = [];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.buildPages();
  }

  ngOnInit(): void {
    // this.setPages();
    this.params.subscribe({
      next: () => {
        this.buildPages();
      },
    });
  }

  buildPages() {
    let pageCount = this.pagination.totalPages;
    let activePage = this.pagination.currentPage;
    if (pageCount <= 4) {
      const pages = [...new Array(pageCount)].map((_, index) =>
        (index + 1).toString()
      );
      this.pages = pages;
      return pages;
    }

    const pages = ['1'];

    if (activePage >= 4) {
      pages.push('...');
    }

    if (activePage + 1 > pageCount) {
      pages.push((activePage - 2).toString());
    }

    if (activePage - 1 > 1) {
      pages.push((activePage - 1).toString());
    }

    if (activePage !== 1) {
      pages.push(activePage.toString());
    }

    if (activePage + 1 <= pageCount) {
      pages.push((activePage + 1).toString());
    }

    if (activePage - 1 <= 0) {
      pages.push((activePage + 2).toString());
    }

    if (activePage + 2 < pageCount) {
      pages.push('...');
    }

    if (activePage + 1 < pageCount) {
      pages.push(pageCount.toString());
    }

    this.pages = pages;
  }

  setPages() {
    const current = this.pagination?.currentPage;
    this.pages = [current];
  }

  onChangePage(page: number) {
    const params = this.params.getValue();
    params.page = page;
    this.params.next(params);
    this.changePage.emit(page);
  }

  nextPage() {
    if (this.pagination.currentPage == this.pagination.totalPages) {
      return;
    }
    const params = this.params.getValue();
    params.page++;
    this.params.next(params);
  }

  pageChange(page: string) {
    if (page == '...') {
      return;
    }
    if (Number(page) == this.pagination.currentPage) {
      return;
    }
    const params = this.params.getValue();
    params.page = Number(page);
    this.params.next(params);
  }

  previusPage() {
    if (this.pagination.currentPage == 1) {
      return;
    }
    const params = this.params.getValue();
    params.page--;
    this.params.next(params);
  }
}
