import { Component, Input, OnInit } from '@angular/core';
import { TableHeader } from '@shared/interfaces/table-header.interface';
import { SearchParams } from '@utils/classes/list-params';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'float-filters',
  templateUrl: './float-filters.component.html',
  styles: [],
})
export class FloatFiltersComponent implements OnInit {
  @Input() headers: TableHeader[] = [];
  filterableHeaders: TableHeader[] = [];
  @Input() params = new BehaviorSubject(new SearchParams());

  ngOnInit(): void {
    this.filterableHeaders = this.headers.filter(header => header.filterable);
  }

  applyFilters() {
    const filters = this.headers.filter(header => header.filter);
    const filledFilters = filters.filter(filter => filter.filter.value);
    const params = new SearchParams();
    filledFilters.forEach(filter => {
      params.addFilter(
        filter.column,
        filter.filter.value,
        filter.filter.operator
      );
    });
    this.params.next(params);
  }

  cleanFilters() {
    this.headers.forEach(header => {
      header.filter.value = null;
    });
    const params = new SearchParams();
    this.params.next(params);
  }
}
