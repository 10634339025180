import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeEs from '@angular/common/locales/es-MX';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AppInitService } from '@common/initializer/app-init.service';
import { DEFAULT_TOAST_CONFIG } from '@config/toastr/toastr.config';
import { CoreModule } from '@core/core.module';
import { AuthGuard } from '@core/guard/auth.guard';
import { LoginGuard } from '@core/guard/login.guard';
import { ErrorInterceptor } from '@core/interceptor/error.interceptor';
import { fakeBackendProvider } from '@core/interceptor/fake-backend';
import { environment } from '@environments/environment';
import { AuthLayoutComponent } from '@layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@layout/app-layout/main-layout/main-layout.component';
import { HeaderComponent } from '@layout/header/header.component';
import { PageLoaderComponent } from '@layout/page-loader/page-loader.component';
import { RightSidebarComponent } from '@layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from '@layout/sidebar/sidebar.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localeEs, 'es-MX');

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('token');
}

export function servicesOnRun(app: AppInitService) {
  return () => app.load();
}

const config: SocketIoConfig = { url: environment.SOCKET_IO_URL, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ClickOutsideModule,
    MatDialogModule,
    NgScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoadingBarRouterModule,
    CoreModule,
    SharedModule,
    MatProgressBarModule,
    ToastrModule.forRoot(DEFAULT_TOAST_CONFIG),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: environment.JWT_ALLOWED_DOMAINS,
      },
    }),
    NgxPermissionsModule.forRoot(),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: servicesOnRun,
      multi: true,
      deps: [AppInitService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    AuthGuard,
    LoginGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
