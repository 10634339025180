<div class="export-button m-l-10" matTooltip="Columnas">
  <img src="assets/images/icons/columns.png" [matMenuTriggerFor]="menu" />
</div>
<mat-menu #menu="matMenu">
  <button
    class="column-select"
    mat-menu-item
    [disabled]="
      (visibleHeaders.length <= minHeaders && header.show) || header.showAlways
    "
    *ngFor="let header of allHeaders"
    (click)="selectColumn($event, header)">
    <div class="w-100 d-flex justify-content-between">
      <div>
        {{ header.label }}
      </div>
      <div>
        <mat-checkbox
          [disabled]="
            (visibleHeaders.length <= minHeaders && header.show) ||
            header.showAlways
          "
          color="primary"
          [checked]="header.show"
          (click)="selectColumn($event, header)">
        </mat-checkbox>
      </div>
    </div>
  </button>
</mat-menu>
