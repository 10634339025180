<div class="row">
  <div class="col-md-6">
    <p>
      <small
        >Pagina {{ pagination.currentPage }} - {{ pagination.totalPages }} |
        Total Registros: {{ pagination.totalItems }}</small
      >
    </p>
    <!-- <p>
      Mostrando registros del <strong> </strong> -
      <strong> de un total de </strong>
    </p> -->
  </div>
  <div class="col-md-6 d-flex justify-content-end">
    <nav aria-label="Page navigation example" *ngIf="pagination">
      <ul class="custom-pagination">
        <li
          (click)="previusPage()"
          [ngClass]="{ disabled: pagination.currentPage == 1 }">
          <a tabindex="-1">Anterior</a>
        </li>
        <li
          *ngFor="let page of pages"
          (click)="pageChange(page)"
          [class.active]="page == pagination.currentPage">
          <a>{{ page }}</a>
        </li>
        <li
          (click)="nextPage()"
          [ngClass]="{
            disabled: pagination.currentPage == pagination.totalPages
          }">
          <a>Siguiente</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
