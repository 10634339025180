import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AllowedFilesService {
  private $extensions = new BehaviorSubject<string[]>([]);

  loadExtensions(extensions: string[]) {
    this.$extensions.next(extensions);
  }

  getExtensions() {
    return this.$extensions.getValue();
  }
}
