import { Component, Input } from '@angular/core';

@Component({
  selector: 'content-body',
  templateUrl: './content-body.component.html',
  styles: [],
})
export class ContentBodyComponent {
  @Input() title: string = '';
}
