import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Repository } from '@core/repository/repository';
import { IUser } from '@pages/users/users/interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class UsersService extends Repository<IUser> {
  constructor() {
    super();
    this.path = 'users';
  }

  getByAccountOne(params?: HttpParams) {
    return this.httpClient.get(`${this.route}${this.path}/getByAccountOne`, {
      params,
    });
  }

  uploadProfilePicture(formData: FormData, id: number) {
    return this.httpClient.post(
      `${this.route}${this.path}/user-picture/${id}`,
      formData
    );
  }

  updateUserPasswordRole(id: number, formData: Object) {
    return this.httpClient.put(
      `${this.route}${this.path}/PasswordAndRole/${id}`,
      formData
    );
  }

  assignAccount(userId: number, accountId: number) {
    const url = `assign-account/${userId}/${accountId}`;
    return this.post({ url, body: {} });
  }

  denyAccount(userId: number, accountId: number) {
    const url = `deny-account/${userId}/${accountId}`;
    return this.post({ url, body: {} });
  }
}
