import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationResponse } from '@core/interfaces/pagination-response.interface';
import { Repository } from '@core/repository/repository';
import { IArea } from '@customer/areas/interfaces/area.interface';

@Injectable({ providedIn: 'root' })
export class AreasService extends Repository<IArea> {
  constructor() {
    super();
    this.path = 'areas';
  }

  getByUser(customerId: number) {
    const url = `by-user/${customerId}`;
    return this.get<IArea[]>({ url });
  }

  findByCustomer(customerId: number, params?: HttpParams) {
    const url = `by-customer/${customerId}`;
    return this.get<IPaginationResponse<IArea>>({ url, params });
  }
}
